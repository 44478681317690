import { Component, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-calendar-picker',
  templateUrl: './calendar-picker.component.html',
  styleUrls: ['./calendar-picker.component.css']
})
export class CalendarPickerComponent implements OnInit {

  selected: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment().subtract(0, 'days'), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 15 Days': [moment().subtract(14, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Week': [moment().startOf('isoWeek'), moment()],
    'This Month': [moment().startOf('month'), moment()]
  }

  constructor() { }

  ngOnInit() {
  }

}
