import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

import { SharedRoutingModule } from "./shared-routing.module";
import { CalendarPickerComponent } from "./calendar-picker/calendar-picker.component";
import { HttpClient } from "@angular/common/http";
import { AppMenuService } from "src/app/app-menu/app-menu.service";

@NgModule({
  declarations: [CalendarPickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgxDaterangepickerMd.forRoot({
      separator: " - ",
      applyLabel: "Apply",
      cancelLabel: "Cancel",
    }),
    SharedRoutingModule,
  ],
  providers: [AppMenuService],
  exports: [CalendarPickerComponent],
})
export class SharedModule {}
